import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Metadata, Page } from '~/components/Layout'
import ProductPage from '~/components/Product/Page'


const ProductTemplate = ({ data: { product, secondaryProduct, tertiaryProduct }, location }) => {
  const { name, metadata, shortDescription } = product
  const secondProduct = secondaryProduct && location.pathname.includes(secondaryProduct?.slug)
  const thirdProduct = tertiaryProduct && location.pathname.includes(tertiaryProduct?.slug)

  const metaTitle = thirdProduct ? tertiaryProduct?.metadata?.title
                    : secondProduct ? secondaryProduct?.metadata?.title
                    : metadata?.title

  const metaDescription = thirdProduct ? tertiaryProduct?.metadata?.description?.description
                        : secondProduct ? secondaryProduct?.metadata?.description?.description
                        : metadata?.description?.description

  const metaImage = thirdProduct ? tertiaryProduct?.metadata?.image?.file?.url
                    : secondProduct ? secondaryProduct?.metadata?.image?.file?.url
                    : metadata?.image?.file?.url

  return (
    <Page>
      <Metadata
        title={metaTitle || name}
        description={metaDescription || shortDescription}
        image={metaImage}
      />
      <ProductPage product={product} secondaryProduct={secondaryProduct} tertiaryProduct={tertiaryProduct} />
    </Page>
  )
}

export default ProductTemplate

export const query = graphql`
  query ProductQuery($slug: String, $secondarySlug: String, $tertiarySlug: String, $locale: String) {
    product: contentfulProduct(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
    secondaryProduct: contentfulProduct(
      slug: { eq: $secondarySlug }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
    tertiaryProduct: contentfulProduct(
      slug: { eq: $tertiarySlug }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
  }
`

const imageType = PropTypes.shape({
  file: PropTypes.shape({
    url: PropTypes.string,
  })
})

ProductTemplate.propTypes = {
  data: PropTypes.shape({
    product: PropTypes.shape({
      name: PropTypes.string,
      shortDescription: PropTypes.string,
      mainImage: imageType,
      metadata: PropTypes.shape({
        description: PropTypes.shape({
          description: PropTypes.string,
        }),
        image: imageType,
        title: PropTypes.string
      })
    }),
    secondaryProduct: PropTypes.shape({
      name: PropTypes.string,
      shortDescription: PropTypes.string,
      mainImage: imageType,
      metadata: PropTypes.shape({
        description: PropTypes.shape({
          description: PropTypes.string,
        }),
        image: imageType,
        title: PropTypes.string
      })
    }),
    tertiaryProduct: PropTypes.shape({
      name: PropTypes.string,
      shortDescription: PropTypes.string,
      mainImage: imageType,
      metadata: PropTypes.shape({
        description: PropTypes.shape({
          description: PropTypes.string,
        }),
        image: imageType,
        title: PropTypes.string
      })
    })
  })
}
