import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Container, Heading } from 'theme-ui'
import PageSection from '~/sections/PageSection'
import { useLocation } from '@reach/router'
import Header from '~/sections/Product/Header'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import { useReviews } from '~/hooks/components/use-reviews'
import { Line, ReviewsWrapper } from './styles'
import useProductContext, {
  ProductContextProvider
} from '~/hooks/components/use-product-context'
import RichResults from '~/components/RichResults'
import useProductRichResults from '~/hooks/components/use-product-rich-results'

var observeDOM = function (obj, callback) {
  var MutationObserver =
    window.MutationObserver || window.WebKitMutationObserver

  if (!obj || !obj.nodeType === 1) return // validation

  if (MutationObserver) {
    // define a new observer
    var obs = new MutationObserver(function (mutations, observer) {
      if (mutations[0].addedNodes.length) callback(mutations[0])
    })
    // have the observer observe foo for changes in children
    obs.observe(obj, { childList: true })
  } else if (window.addEventListener) {
    obj.addEventListener('DOMNodeInserted', callback, false)
  }
}

const ProductPageChildren = () => {
  const context = useProductContext()
  const { currentProduct, currentVariant, selectVariant } = context
  const [widgetRef] = useRef(null)

  useReviews()

  const { trackProductViewed } = useAnalytics()
  useEffect(() => {
    trackProductViewed({
      product: currentProduct
    })

    window.analytics?.track('Viewed Product', {
      Name: currentProduct.name,
      Brand: 'Caraway',
      Categories: currentProduct.collection[0]?.slug,
      Price: currentProduct.variants[0]?.price,
      CompareAtPrice: currentProduct.variants[0]?.regularPrice,
      URL: window.location.href,
      ProductId: currentProduct.productId,
      ImageURL: currentProduct.mainImage.file.url
    })
  }, [trackProductViewed, currentProduct.slug])

  const richResults = useProductRichResults({ currentProduct, currentVariant })
  const { pathname } = useLocation()

  return (
    <>
      <RichResults richResults={richResults} />
      <Header />

      {currentProduct.sections &&
        currentProduct.sections.map(section => {
          return (
            <PageSection
              key={section.id}
              section={section}
              currentVariant={currentVariant}
              selectVariant={selectVariant}
              currentProduct={currentProduct}
            />
          )
        })}

      {currentProduct.slug !== 'gift-card' && <Line />}

      <ReviewsWrapper>
        <Container>
          <Heading as="h2" variant="secondaryLarge">
            Customer Reviews
          </Heading>
          {currentProduct?.showReviews ? (
            <div
              ref={widgetRef}
              id="page-reviews"
              className="yotpo yotpo-main-widget"
              data-name={currentProduct.name}
              data-url={pathname}
              data-image-url={currentProduct.metadata?.file?.image?.url}
              data-description={
                currentProduct.metadata?.description?.description
              }
              data-product-id={currentProduct.productId}
            />
          ) : (
            <div id="yotpo-testimonials-custom-tab" />
          )}
        </Container>
      </ReviewsWrapper>
    </>
  )
}

const ProductPage = ({ product, secondaryProduct, tertiaryProduct }) => (
  <ProductContextProvider
    product={product}
    secondaryProduct={secondaryProduct}
    tertiaryProduct={tertiaryProduct}
  >
    <ProductPageChildren />
  </ProductContextProvider>
)

ProductPage.propTypes = {
  product: PropTypes.shape({
    slug: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({})),
    variants: PropTypes.arrayOf(PropTypes.shape({})),
    productId: PropTypes.number
  }),
  secondaryProduct: PropTypes.shape({
    slug: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({})),
    variants: PropTypes.arrayOf(PropTypes.shape({})),
    productId: PropTypes.number
  }),
  tertiaryProduct: PropTypes.shape({
    slug: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({})),
    variants: PropTypes.arrayOf(PropTypes.shape({})),
    productId: PropTypes.number
  })
}

export default ProductPage
