import styled from '@emotion/styled'

export const Line = styled.hr`
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 48px 0 0;
  border: none;
  overflow: visible;
  background: repeating-linear-gradient(
    90deg,
    #aec9d3,
    #aec9d3 2px,
    transparent 0,
    transparent 8px
  );

  @media screen and (min-width: 768px) {
    margin: 64px 0 0;
  }

  &:before {
    display: block;
    position: relative;
    top: -12px;
    content: '';
    height: 5px;
    width: 100%;
    background: #aec9d3;
    margin-bottom: 4px;
  }
`

export const ReviewsWrapper = styled.div`
  padding: 48px 0;

  @media screen and (min-width: 992px) {
    padding: 64px 0;
  }

  h2 {
    text-align: center;
  }

  .yotpo-label-container {
    display: none;
  }

  .yotpo-default-button.yotpo-icon-btn.write-question-review-button.write-button.write-review-button {
    width: auto;
    height: auto !important;
    padding: 15px 20px !important;
    margin-top: 0px;
    background-color: #f7e6c1;
    border: 0;
    border-radius: 50px;
  }

  .yotpo span.suggested-topic {
    background-color: #f7e6c1;
    border-radius: 50px;
    padding: 8px 20px !important;
  }

  .yotpo .new-yotpo-small-box {
    border: none;
  }

  .yotpo .yotpo-nav {
    display: none;
  }

  .yotpo .yotpo-display-wrapper .write-question-review-buttons-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .yotpo .bottom-line-items-wrapper {
    // justify-content: space-between !important;
    // padding: 0.75rem 0;
    // position: relative !important;
    // left: 0 !important;
    // right: 0 !important;
    // margin: auto !important;
    // transform: none !important;


display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    .botton-line-items {
      float: none;
    }
  }

  .yoptpo yotpo-star-distribution-wrapper {
    float: none;
  }

  .yotpo .rating-stars-container {
    float: none !important;
    margin: 10px auto;
    display: flex !important;
    justify-content: center;
  }

  .yotpo .reviews-qa-labels-container {
    padding-top: 8px;
  }

  .yotpo .yotpo-distribution-row {
    width: 210px !important;
  }

  .yotpo .yotpo-rating-data {
    float: right;
    width: 85px;
    font-size: 12px;

    span {

    font-size: 12px;
    }
  }

  .yotpo .reviews-qa-label {
    white-space: nowrap;
  }

  .yotpo .form-group {
    display: block;
  }

  .yotpo .y-label {
    display: inline;
  }

  .yotpo .form-group .form-element .y-label {
    display: inline;
    white-space: normal;
  }

  .yotpo .write-review .connect-wrapper .form-element .y-input {
    margin: 15px 0 20px;
  }

  .yotpo .filters-dropdown[data-type='scores'] {
    display: none;
  }

  .yotpo .footer-actions {
    display: none !important;
  }

  .yotpo .yotpo-review {
    position: relative;
  }

  .yotpo .yotpo-review .yotpo-footer {
    position: unset;
  }

  .yotpo .yotpo-review-date {
    font-size: 12px;
    position: absolute;
    top: 26px;
    right: 0;
  }

  .yotpo .yotpo-review.yotpo-regular-box-filters-padding .yotpo-review-date {
    top: 12px;
  }

  .yotpo .yotpo-header span.yotpo-icon-profile, .yotpo.yotpo-modal .yotpo-header span.yotpo-icon-profile {
    background: url(//cdn.shopify.com/s/files/1/0258/6273/3906/t/347/assets/chef.svg?v=4707762513063460990) center no-repeat !important;
    background-size: 30px !important;

    
`
