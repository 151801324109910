import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'

const priceValidUntil = moment().add(1, 'days').format('YYYY-MM-DD')

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    reviews: allYotpoProductReview {
      products: group(field: productId, limit: 10) {
        id: fieldValue
        reviews: nodes {
          content
          createdAt
          score
          reviewerName
        }
      }
    }
    bottomlines: allYotpoProductBottomline {
      products: group(field: productId, limit: 10) {
        id: fieldValue
        bottomline: nodes {
          totalReviews
          score
        }
      }
    }
  }
`

const useProductRichResults = ({ currentProduct, currentVariant }) => {
  const {
    site: {
      siteMetadata: { siteUrl }
    },
    reviews: { products },
    bottomlines: { products: bottomlineProducts }
  } = useStaticQuery(QUERY)
  const { name, slug, longDescription, variants, productId } = currentProduct

  const product = useMemo(() => {
    const reviews = products.find(
      ({ id }) => id === productId?.toString()
    )?.reviews
    const bottomline = bottomlineProducts.find(
      ({ id }) => id === productId?.toString()
    )?.bottomline?.[0]

    return {
      reviews,
      bottomline
    }
  }, [products, bottomlineProducts])

  const bestRating = useMemo(
    () =>
      product?.reviews?.reduce(
        (acc, review) => (review.score > acc ? review.score : acc),
        0
      ),
    []
  )

  const {
    globalTradeItemNumber,
    mainImage: {
      file: { url: imageUrl }
    },
    sku: currentVariantSku
  } = currentVariant

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    url: `${siteUrl}/products/${slug}`,
    gtin8: globalTradeItemNumber,
    image: [imageUrl],
    description: longDescription?.longDescription,
    sku: currentVariantSku,
    brand: {
      '@type': 'Brand',
      name: 'Caraway'
    },
    offers: variants.map(({ price, sku, optionValues }) => {
      const urlParams = (optionValues || []).map(
        v => `${v.option_type[0].presentation}=${v.presentation}`
      )
      return {
        '@type': 'Offer',
        availability: 'https://schema.org/InStock',
        itemCondition: 'https://schema.org/NewCondition',
        url: `${siteUrl}/products/${slug}${`${
          urlParams.length && '?'
        }${urlParams.join('&')}`}`,
        sku,
        price: `${price}`,
        priceCurrency: 'USD',
        priceValidUntil
      }
    }),
    review: product?.reviews?.map(review => ({
      '@type': 'Review',
      reviewBody: review.content,
      datePublished: review.createdAt,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: review.score,
        bestRating
      },
      author: {
        '@type': 'Person',
        name: review.reviewerName
      }
    })),
    aggregateRating: product?.bottomline
      ? {
          '@type': 'AggregateRating',
          ratingValue: Math.round(product.bottomline.score * 100) / 100,
          reviewCount: product.bottomline.totalReviews
        }
      : null
  }
}

export default useProductRichResults
